<template>
  <div class="pageMain">
    <search-head @reset="headReset" @search="headSearch">
      <el-form :inline="true" :model="searchForm">
        <el-form-item label="所属机构">
          <el-select v-model="searchForm.project" size="small" class="block-select" placeholder="请选择所属机构">
              <el-option
                  v-for="item in projectList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="订单状态">
          <el-select v-model="searchForm.payState" size="small" class="block-select" placeholder="请选择订单状态">
              <el-option
                  v-for="item in payStateList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
        </el-form-item>
        <!-- <el-form-item label="对账状态">
          <el-input
              size="small"
              clearable
              v-model="searchForm.compName"
              placeholder="请输入证件号"
          ></el-input>
        </el-form-item> -->
        <el-form-item label="订单编号">
          <el-input
              size="small"
              clearable
              v-model="searchForm.payNo"
              placeholder="请输入证件号"
          ></el-input>
        </el-form-item>
        <el-form-item label="订单时间">
          <el-date-picker
              size="small"
              v-model="searchForm.createTime"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="支付时间">
          <el-date-picker
              size="small"
              v-model="searchForm.modifyTime"
              type="date"
              placeholder="选择日期">
          </el-date-picker>
        </el-form-item>
      </el-form>
    </search-head>
    <custom-tables
        :data="tableData"
        :total="total"
        noall="true"
        isadd="true"
        noimport="true"
        title="订单列表"
        @selectionChange="selectionChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSizeChange="handleSizeChange"
        @tableExport="tableExport">
      <el-table-column type="selection" align="center" width="55"></el-table-column>
      <el-table-column prop="payNo" label="订单编号" width="180"></el-table-column>
      <el-table-column prop="projName" label="所属机构"></el-table-column>
      <el-table-column prop="userName" label="姓名"></el-table-column>
      <el-table-column prop="phone" label="联系电话"></el-table-column>
      <el-table-column prop="money" label="订单金额(元)"></el-table-column>
      <el-table-column prop="payState" label="订单状态">
        <template slot-scope="scope">
          <span v-if="scope.row.payState == 0" style="color: red;">待支付</span>
          <span v-if="scope.row.payState == 1" style="color: rgb(32, 150, 71);">已支付</span>
          <!-- <span v-if="scope.row.payState == 2" >待审核</span> -->
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="订单时间"></el-table-column>
      <el-table-column prop="modifyTime" label="支付时间"></el-table-column>
      <!-- <el-table-column prop="compAddress" label="对账状态"></el-table-column> -->
      <!-- <el-table-column prop="compAddress" label="对账时间"></el-table-column> -->
<!--      <el-table-column label="操作" align="center" width="100">-->
<!--        <template slot-scope="scope">-->
<!--          <span class="btn-icon" @click="editItem(scope.row)">详情</span>-->
<!--        </template>-->
<!--      </el-table-column>-->
    </custom-tables>
  </div>
</template>

<script>
import basic from "../../../api/modules/basic";
import CustomTables from "../../../components/common/CustomTables";
import SearchHead from "../../../components/common/SearchHead";
import {downloadFile} from "../../../utils/tool";

export default {
  name: "orderInfo",
  components: { SearchHead, CustomTables},
  data() {
    return {
      dialogTitle: "新增酒店",
      showDialog: false,
      total: 0,
      tableData: [],
      searchForm: {
        pageNum: 1,
        pageSize: 10,
      },
      item: {},
      projectList:[],
      payStateList:[{label:'待支付',value:0},{label:'已支付',value:1}]
    };
  },
  mounted() {
    this.getInfo();
    this.getProjectLists()
  },
  methods: {
    getInfo() {
      basic.getOrderInfo(this.searchForm).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.records;
      });
    },
    handleCurrentChange(pageIndex) {
      this.searchForm.pageNum = pageIndex;
      this.getInfo();
    },
    handleSizeChange(pageSize) {
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = pageSize;
      this.getInfo();
    },
    editDone() {
      // 编辑成功返回
      this.showDialog = false;
      this.getInfo();
    },
    headReset() {
      this.searchForm = {
        pageNum: 1,
        pageSize: 10,
      };
      this.getInfo();
    },
    headSearch() {
      this.searchForm.pageNum = 1;
      this.searchForm.pageSize = 10;
      this.getInfo();
    },
    tableDel() {
    },
    //导出
    tableExport() {
      let param = {
        pageNum: this.searchForm.pageNum,
        pageSize: this.searchForm.pageSize,
      };
      basic.getCompanyRecExport(param).then((res) => {
        downloadFile(res)
      }).catch(err => {
        console.log(err, 'err');
      })
    },
    //格式化json
    formatJson(filterVal, jsonData) {
      return jsonData.map((v) => filterVal.map((j) => v[j]));
    },
    selectionChange(val) {
      console.log(val);
    },
    editItem(item) {
      this.item = item;
      this.dialogTitle = "订单详情";
      this.showDialog = true;
    },
    getProjectLists(){
      basic.getProjectList().then((res) => {
       console.log(res);
       res.data.map(item=>{
        this.projectList.push({
          label:item.projName,
          value:item.projId
        })
       })
      }).catch(err => {
        console.log(err, 'err');
      })
    }
  },
};
</script>

<style scoped></style>
